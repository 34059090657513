@import 'styles/index.sass'

.ItemShowContent
    margin-top: 40px
    padding: 0 64px 0 112px
    width: calc(100% - 64px - 112px)
    display: grid
    grid-template-areas: "carousel-padination .carousel-slides .short-data" ". .data . ."
    grid-template-columns: 77px 1fr minmax(200px, 544px) 1fr 232px

    @media (max-width: $breakpoint-tablet-small)
        padding: 0 44px
        width: calc(100% - 44px * 2)
        display: block

.ItemShowWarningSizes
    position: relative

.ItemShowWarningIcon
    position: absolute
    top: 0
    left: -5px

.ItemShowCarouselPagination
    grid-area: carousel-padination

.ItemShowCarouselSlides
    grid-area: carousel-slides

.ItemShowShortData
    grid-area: short-data
    display: flex
    flex-direction: column
    align-items: center

    @media (max-width: $breakpoint-tablet-small)
        margin-top: 48px

.ItemShowLogo
    margin-bottom: 12px
    font-family: American Text
    color: #fff
    font-size: 40px
    line-height: 57px
    text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.6)

    @media (max-width: $breakpoint-tablet-small)
        margin-bottom: 22px
        font-size: 32px
        line-height: 46px

.ItemShowPriceWrapper
    margin-bottom: 42px
    font-family: American Text
    font-size: 24px
    line-height: 34px
    -webkit-text-stroke: 0.926285px #fff
    text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.6)

    @media (max-width: $breakpoint-tablet-small)
        font-size: 20px
        line-height: 29px

.ItemShowPrice
    font-family: Gotyk Poszarpany

.ItemShowWarning
    margin-bottom: 18px
    font-size: 18px
    line-height: 21px
    -webkit-text-stroke: 0.75px #fff
    text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.6)
    font-family: Gotyk Poszarpany

.ItemShowButton
    width: 100%
    max-width: 335px
    font-family: Gotyk Poszarpany

.ItemShowBuyNow
    display: block
    width: 100%
    max-width: 200px
    color: #fff
    background: #fc5a5a
    text-align: center
    padding: 1rem 0rem
    margin-bottom: 1rem
    border-radius: 9999px
    font-family: Gotyk Poszarpany
    text-decoration: none

.ItemShowData
    margin-top: 50px
    grid-area: data

    @media (max-width: $breakpoint-tablet-small)
        margin-top: 47px

.ItemShowOptionsTitle
    filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.6))

.ItemShowSizeError
    color: #fc5a5a
    font-family: Gotyk Poszarpany
    font-size: 1.5rem
    margin-top: 1rem
