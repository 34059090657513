.CarouselMobile
    display: flex
    flex-direction: column
    align-items: center

.CarouselMobileSlider
    overflow: hidden
    padding-bottom: 1rem

.CarouselMobileSlidesContainer
    display: flex
    overflow-x: scroll
    overflow-y: hidden
    scroll-snap-type: x mandatory

.CarouselMobileSlide
    margin-bottom: 18px
    width: 100%
    // max-width: 287px
    height: 285px
    flex-shrink: 0
    scroll-snap-align: center
    scroll-snap-stop: always

.CarouselMobileSlideImage
    width: 100%
    height: 100%
    object-fit: contain

.CarouselMobileDots
    display: flex

.CarouselMobileDot
    margin-right: 8px
    width: 8px
    height: 8px
    border: 1px solid #fff
    border-radius: 50px
    cursor: pointer

    &:last-child
        margin-right: 0

.CarouselMobileDot_active
    background: #fff
