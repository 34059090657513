#my-store-10960248 * {
  color: #fff;
  font-family: Manrope, sans-serif !important;
}

#my-store-10960248.ecwid {
  margin-top: 1rem !important;
}

#my-store-10960248 .form-control__inline-label label{
  background-color: transparent !important;
}

#my-store-10960248 .form-control__inline-label:hover label{
  background-color: #fff !important;
  border: none;
}

#my-store-10960248 .form-control__inline-label:hover label span{
  color: #000;
}

.ecwid-productBrowser {
  padding: 0 !important;
}

.ec-cart {
  flex-direction: column;
}

.ec-cart__sidebar {
  order: 1;
  width: 100% !important;
  padding: 0 !important;
}

.ec-cart__body {
  order: 2;
  width: 100% !important;
  padding: 0 !important;
  
}

// Remove footer {

.ec-footer {
  display: none;
}

// Checkout title 

.page-title__name.page-title__name--mobile.ec-header-h1 {
  font-size: 1rem;
}

// Order Summary

.ec-cart__sidebar {
  order: 1;
  position: static;
  padding-bottom: 20px !important;
}

.ec-cart__sidebar-inner {
  // padding-bottom: 1rem !important;
}

.ec-cart-shopping__wrap {
  display: none;
}

.ec-cart__shopping.ec-cart-shopping {
  margin-bottom: 0 !important;
}

// Title

.ec-page-title {
  background-color: #343434 !important;
  padding: 16px 20px !important;
  margin-bottom: 1rem !important;
}

.page-title__name.ec-header-h1 {
  margin: 0 !important;
  font-family: Manrope, sans-serif;
  font-size: 1rem !important;
}

// Remove breadcrumbs

div.ec-breadcrumbs {
  display: none !important;
}

// Select quantity color text

.form-control__select-text {
  color: #b7b7b7 !important;
}

// Cart items

.ec-cart__products {
  padding: 0 20px !important;
  border-bottom-color: #484848 !important;
}

// Item picture

.ec-cart-item__picture {
  width: 68px !important;
  border: 1px solid #212121 !important;
  border-radius: 12px;
  cursor: default;
  pointer-events: none;
}

// item title

#my-store-10960248 a.ec-cart-item__title {
  font-family: American Text !important;
  cursor: default;
  pointer-events: none;
}

// Quanity 

.ec-cart-option--key {
  color: #b7b7b7 !important;
}

// Subtotal

.ec-cart__summary.ec-cart-summary {
  display: block !important;
  padding: 0 20px !important;
  margin-bottom: 0 !important; 
}

.ec-cart-summary__cell.ec-cart-summary__title {
  text-transform: capitalize !important;
  font-weight: normal !important;
}

// Email 

.ec-cart__email.ec-cart-email {
  padding: 0 20px !important;
}

.form-control {
  background-color: #101010 !important;
}

.form-control__text {
  padding: 5px 16px !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #fff !important;
  border: 1px solid #484848 !important;
  border-radius: 12px !important;
}

// Email Step

.ec-cart__steps {
  border-top-color: #676767 !important;
}

.ec-cart-step__change.ec-link {
  // Change email
  color: #bfbfbf !important;
}

// Checkout button

.ec-cart__checkout {
  padding: 20px 20px 0 !important;
}

.form-control__button {
  background: #fff !important;
  border-radius: 40px !important;
  height: 64px !important;
}

.form-control__button-text {
  color: #000 !important;
  font-weight: bold !important;
  font-size: 16px !important;
}

// Steps

.ec-cart__next.ec-cart-next {
  padding: 20px !important;
}



.ec-cart-next__header.ec-header-h4 {
  border-bottom-color: #676767 !important;
}

.ec-cart-step--current {
  border: none !important
}

// Steps done

.ec-cart-step--done {
  margin: 20px 0 !important;
  border: none !important;
}

.ec-cart-step__wrap {
  padding-top: 0 !important;
  border: none !important;
}

.ec-cart-step--done:hover .ec-cart-step__title {
  color: #676767 !important;
}

// Address

.ec-form__title.ec-header-h6 {
  font-size: 14px !important;
  color: #b7b7b7 !important;
  font-weight: normal !important;
  margin-bottom: 6px !important;
}

.ec-store .form-control__text {
  height: 55px !important;
  min-height: 55px !important;
}

.form-control__label {
  padding-left: 14px !important;
  bottom: auto !important;
  top: 5px !important;
}

// Shipping title

.ec-cart-step__title.ec-header-h4 {
  background-color: #343434 !important;
  padding: 16px 20px !important;
  margin-bottom: 1rem !important;
  font-size: 16px !important;
}

// Shipping method choice

.ec-tabs__tab.ec-tabs__tab--active {
  border-color: #fff !important;
  box-shadow: 0 0 0 1px #aaa !important;
}

.ec-tabs__text {
  color: #676767 !important;
}

// Next steps

.ec-cart-step__body {
  padding: 0 20px !important;
}

.ec-cart-step__next.ec-header-h5,
.ec-cart-step__title,
.ec-cart-step__body .c-text-muted {
  padding: 10px 20px !important;
}

.ec-cart-step__next.ec-header-h5 {
  border-bottom-color: #676767 !important;
}

.ec-cart__step.ec-cart-step.ec-cart-step--payment.ec-cart-step--current {
  border-top-color: #676767 !important;
}

// Shipping method step

.ec-radiogroup__items {
  border: none !important;
}

// Radio buttons

.ec-radiogroup__radio,
.ec-radiogroup__item-wrap {
  padding: 10px 10px !important;
  background-color: none;
}

.ec-radiogroup__item-wrap {
  flex-direction: row !important;
  align-items: center !important;
}

.ec-radiogroup__radio {
  min-height: 2rem !important;
}

.ec-radiogroup__item {
  min-height: 2.5rem !important;
  border-radius: 50px !important;
  background-color: #101010 !important;
  margin-top: 20px !important;
  padding: 0px !important;
  box-shadow: none !important;
  border: 1px solid #676767 !important;
}

// Payment methods

.ecwid-PaymentMethodsBlockSvg {
  width: 27px !important;
  height: auto !important;
}

// Step description

.ec-cart-step__section {
  color: #757575 !important;
}

// Thank you page

.ec-confirmation {
  padding: 0 20px !important;
}

.ec-confirmation__email {
  margin-top: 20px !important;
}

.ec-confirmation__title.ec-header-h5, .ec-confirmation__number.ec-header-h5 {
  margin-bottom: 10px !important;
}

.ec-confirmation__step {
  border-color: #676767 !important;
}

.ec-confirmation__section .ec-cart__products {
  padding: 0 !important;
}

.ec-confirmation__continue .form-control__button {
  display: none !important;
}

.ec-link {
  color: #676767 !important;
}


@media screen and (min-width: 480px) {
  .ec-cart-item__image {
    display: block !important;
  }

  .ec-cart-item__picture {
    width: 68px !important;
    border: 1px solid #212121 !important;
    border-radius: 12px;
  }
}

@media screen and (min-width: 768px) {
  
  // Ecwid widget width

  #my-store-10960248.ecwid {
    width: 768px;
    margin: 2rem auto 0 !important;
  }

  .ec-cart {
    flex-direction: row;
    padding: 0 20px !important;
  }

  // Page Title
  
  .ec-page-title {
  padding: 16px 20px !important;
  margin-bottom: 24px !important;
}

  // Order summary

  .ec-cart__sidebar {
    order: 2;
    width: 50% !important;
    border: 1px solid #343434 !important;
    padding: 0 !important;
  }

  .ec-cart__sidebar-inner {
    position: static !important;
  }
  // Product title

  .ec-cart-item__title {
    font-size: 18px !important;
  }

  .ec-cart-item__price-inner {
    font-size: 16px !important;
  }

  // Information

  .ec-cart__body {
    order: 1;
    width: 50% !important;
    border: 1px solid #343434 !important;
    border-right: none !important;
    padding: 0 !important;
  }

  // Steps done

  .ec-cart-step__block {
    padding-left: 20px !important;
  }

  .ec-cart-step__icon.ec-cart-step__icon--done {
    border-color: #707070 !important;
  }

  .ec-form__row.ec-form__row--continue {
    justify-content: center;
  }

}
