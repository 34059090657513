@import 'styles/index.sass'

.ItemShowOptions
    margin-bottom: 32px
    position: relative
    display: flex
    flex-direction: column
    align-items: center

.ItemShowOptionsChildren
    width: 116px
    height: 116px
    display: flex
    justify-content: center
    align-items: center
    border-radius: 100px
    border: 1px solid rgba(255, 255, 255, 0.2)

    // @media (max-width: $breakpoint-tablet-small)
        // margin-bottom: 28px

.ItemShowOptionsSelct
    display: flex
    font-size: 18px
    line-height: 21px
    color: #fff
    text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.6)
    font-family: Gotyk Poszarpany
    cursor: pointer

.ItemShowOptionsSelct_outlined
    margin-left: 5px
    color: #000
    -webkit-text-stroke: 0.75px rgba(255, 255, 255, 0.5)

.ItemShowOptionsSelctArrow
    margin-left: 8px

.ItemShowOptionsSelctArrow_active
    transform: rotate(180deg)

.ItemShowOptionsDropdown
    width: 148px
    visibility: hidden
    opacity: 0
    position: absolute
    bottom: -90px
    background: #101010
    border: 1px solid rgba(255, 255, 255, 0.2)
    border-radius: 0 0 12px 12px
    z-index: 10
    transition: all 0.2s ease
    @media (max-width: $breakpoint-tablet-small)
        bottom: -60px

.ItemShowOptionsDropdown_show
    opacity: 100
    visibility: visible

.ItemShowOptionsOption
    padding: 0 10px
    width: calc(100% - 10px * 2)
    height: 34px
    display: flex
    align-items: center
    border-bottom: 1px solid rgba(255, 255, 255, 0.2)
    color: #fff
    font-family: Gotyk Poszarpany
    cursor: pointer

    &:last-child
        margin-bottom: 11px

.ItemShowOptionsTitle
    margin-bottom: 18px
    font-size: 18px
    line-height: 21px
    -webkit-text-stroke: 0.75px #fff
    text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.6)
    font-family: Gotyk Poszarpany
