// Moon

.moon-enter {
  top: -50%;
}

.moon-enter-done {
  top: 5%;
  transition: all ease 3s;
}

.moon-exit {
  top: 40%;
  opacity: 1;
  transition: all ease 200ms;
}

.moon-exit-active {
  top: 40%;
  opacity: 1;
  transition: all ease 200ms;
}

.moon-exit-done {
  top: 40%;
  opacity: 0;
  transition: all ease 200ms;
}

// Sun

.sun-enter {
  top: 130%;
}

.sun-enter-done {
  top: 80%;
  transition: all ease 3s;
}

@media screen and (max-height: 900px) {
  .sun-enter-done {
    top: 75%;
  }
}

@media screen and (max-height: 660px) {
  .sun-enter-done {
    top: 70%;
  }
}

.sun-exit {
  bottom: 0%;
  top: 40%;
  opacity: 1;
  transition: all ease 200ms;
}

.sun-exit-active {
  top: 40%;
  opacity: 1;
  transition: all ease 200ms;
}

.sun-exit-done {
  top: 40%;
  opacity: 0;
  transition: all ease 200ms;
}

// Top Arrow

.arrow-top-enter {
  height: 0;
}

.arrow-top-enter-active {
  height: 0;
  transition: all ease 3s;
}

.arrow-top-enter-done {
  height: 150px;
  transition: all ease 3s;
}

.arrow-top-exit {
  height: 150px;
}

.arrow-top-exit-active {
  height: 0;
}

// Celestial logo

.logo-enter {
  opacity: 0;
}

.logo-enter-done {
  opacity: 1;
  transition: all ease 3s;
}

.logo-exit {
  opacity: 1;
}

.logo-exit-active {
  opacity: 0;
  transition: all ease 200ms;
}

.logo-exit-done {
  opacity: 0;
  transition: all ease 200ms;
}

// Bottom arrow

.arrow-bottom-enter {
  height: 0;
}

.arrow-bottom-enter-active {
  height: 0;
  transition: all ease 3s;
}

.arrow-bottom-enter-done {
  height: 150px;
  transition: all ease 3s;
}

.arrow-bottom-exit {
  height: 150px;
}

.arrow-bottom-exit-active {
  height: 0;
}

// Explosion

.explosion-enter {
  opacity: 0;
}

.explosion-enter-active {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
  transition: all ease 600ms;
}

.explosion-enter-done {
  opacity: 1;
  transform: translate(-50%, -350%) scale(50);
  transition: all ease 1000ms;
}

// Portal main wrapper appearance

.portalWrapper-enter {
  opacity: 0 !important;
}

.portalWrapper-enter-active {
  opacity: 0 !important;
  transition: opacity ease 600ms;
}

.portalWrapper-enter-done {
  opacity: 1 !important;
  transition: opacity ease 1000ms;
}

//

.portalVideo-enter {
  display: 0;
  opacity: 0;
}

.portalVideo-enter-active {
  opacity: 0;
  display: flex;
}

.portalVideo-enter-done {
  opacity: 1 !important;
  transition: opacity ease 2000ms;
}
