@import 'styles/index.sass'
    
.Collapse
    border-bottom: 1px solid #fff
    color: #fff
    font-family: Gotyk Poszarpany

.CollapseTitle
    padding: 34px 0 34px 0
    font-size: 24px
    line-height: 28px
    cursor: pointer
    text-align: center

    @media (max-width: $breakpoint-tablet-small)
        padding: 24px 0 24px 0
    
.CollapsePlusIcon
    margin-left: 14px
    font-family: 'Manrope', sans-serif
    font-weight: 400
    font-size: 22px
    line-height: 32px

.CollapseDropdown
    padding: 24px 0 36px 0
    border-top: 1px solid rgba(255, 255, 255, 0.2)
    display: none
    font-size: 20px
    line-height: 150%

.CollapseDropdown_active
    display: block