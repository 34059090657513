@import 'styles/index.sass'

.SocialLinksContainer
  width: 50%
  margin: 5rem auto 0
  display: flex
  justify-content: center
  align-items: center
  
  @media (max-width: $breakpoint-tablet-small)
    margin-top: 3rem

.SocialLink
  display: block
  width: 2rem
  height: 2rem
  margin-right: 1rem
  cursor: pointer
  
  @media (max-width: $breakpoint-tablet-small)
    width: 1.5rem
    height: 1.5rem

.SocialLinkImg
  width: 100%
  height: 100%

