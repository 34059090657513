@import 'styles/index.sass'

.BubbleSlider
    display: flex
    flex-direction: column
    align-items: center

.BubbleSliderSlider
    overflow: hidden
    // padding-bottom: 1rem
    width: 346px
    // width: 100%
    position: relative

    @media (max-width: $breakpoint-tablet-small)
        width: 100%

.BubbleSliderSlidesContainer
    display: flex
    overflow-x: hidden
    overflow-y: hidden
    scroll-snap-type: x mandatory
    position: relative
    scroll-behavior: smooth

    @media (max-width: $breakpoint-tablet-large)
        overflow-x: scroll
        padding-bottom: 15px

    @media (max-width: $breakpoint-tablet-small)
        padding: 15px 0 15px

.BubbleSliderSlide
    // margin-bottom: 18px
    display: flex
    justify-content: center
    align-items: center
    width: 100%
    max-width: 346px
    height: 346px
    flex-shrink: 0
    margin-right: 18px
    margin-left: 18px
    overflow: hidden
    scroll-snap-align: center
    scroll-snap-stop: always

    @media (max-width: $breakpoint-tablet-small)
        width: 250px
        height: 250px
        margin-right: 0

.BubbleSliderSlideContent
    width: 100%
    height: 100%

.Navigation
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
    margin: auto
    display: flex
    justify-content: space-between
    z-index: 10000

.Arrow
    top: 42%
    background-image: url('~assets/icons/pages/portalShow/arrow_active.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: 50% 50%
    width: 30px
    height: 50px
    cursor: pointer
    position: absolute
    z-index: 999

    &:hover
        opacity: 100%

    @media (max-width: $breakpoint-tablet-large)
        width: 20px
        height: 30px
        top: 44%
    
    @media (max-width: $breakpoint-tablet-small)
        top: 45%


.ArrowLeft
    left: 1.5rem
    animation-name: arrow-left
    animation-duration: 1s
    animation-iteration-count: infinite
    animation-direction: alternate-reverse
    transform: rotate(180deg)

    @keyframes arrow-left
        0%
            transform: translateX(0) scale(-1.5)

        100%
            transform: translateX(-10px) scale(-1.5)

    @media (max-width: $breakpoint-tablet-small)
        transform: scale(1)
        left: 1rem

.ArrowRight
    right: 1.5rem
    animation-name: arrow-right
    animation-duration: 1s
    animation-iteration-count: infinite
    animation-direction: alternate-reverse

    @keyframes arrow-right
        0%
            transform: translateX(0) scale(1.5)

        100%
            transform: translateX(10px) scale(1.5)

    @media (max-width: $breakpoint-tablet-small)
        transform: scale(-1, 1)
        right: 1rem
