.CarouselPCPaginationItem
    margin-bottom: 16px
    width: 77px
    height: 108px
    cursor: pointer

    &:last-child
        margin-bottom: 0

.CarouselPCPaginationImage
    width: 100%
    height: 100%
    opacity: 0.5
    object-fit: contain

.CarouselPCPaginationImage_active
    opacity: 1

.CarouselPSlideImage
    width: 100%
    height: 100%