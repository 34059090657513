@import 'styles/index.sass'

.DefaultLayout
    padding-bottom: 128px
    width: 100vw
    min-height: calc(100vh - 128px)
    background: #101010

    @media (max-width: $breakpoint-tablet-small)
        padding-bottom: 47px
        min-height: calc(100vh - 47px)