@import 'styles/index.sass'
    
.CartPage
    width: 100%
    height: 100vh
    position: absolute
    top: 0
    left: 0
    display: grid
    grid-template-columns: 1fr 436px
    z-index: 10000

    @media (max-width: $breakpoint-tablet-small)
        grid-template-columns: 20px 1fr


.CartPageLogo
    margin: 40px 0 0 210px

    @media (max-width: $breakpoint-tablet-large)
        margin: 40px 0 0 100px

    @media (max-width: $breakpoint-tablet-small)
        display: none !important

.CartPageBackground
    background: rgba(0, 0, 0, 0.2)
    backdrop-filter: blur(35px)

.CartPageBackground_dark
    background: #101010

.CartPageCart
    padding: 34px 32px
    width: calc(100% - 32px * 2)
    height: calc(100vh - 34px * 2)
    overflow-y: auto
    background: #101010
    border-left: 1px solid #484848

.CartPageCartHeader
    margin-bottom: 40px
    display: flex
    justify-content: space-between
    align-items: center
    color: #fff

    @media (max-width: $breakpoint-tablet-small)
        margin-bottom: 30px

.CartPageCloseIcon
    width: 16px
    height: 16px
    cursor: pointer

    @media (max-width: $breakpoint-tablet-small)
        width: 12px
        height: 12px

.CartPageTitle
    font-weight: 500
    font-size: 21px
    line-height: 140%
    letter-spacing: 0.08em
    text-transform: uppercase

    @media (max-width: $breakpoint-tablet-small)
        font-size: 16px
        line-height: 20px

.CartPageItems
    margin-bottom: 20px
    padding-bottom: 32px
    border-bottom: 1px solid #fff

.CartPageTotalPrice
    margin-bottom: 32px
    width: 100%
    display: flex
    justify-content: space-between
    color: #fff
    font-weight: 600
    font-size: 16px
    line-height: 20px

    @media (max-width: $breakpoint-tablet-small)
        font-size: 16px
        line-height: 20px

.CartPageCheckOut
    width: 100%
    font-weight: bold
    font-size: 16px
    line-height: 22px