@mixin google-font($family)
  @import url("https://fonts.googleapis.com/css2?family=#{$family}")

@include google-font("Manrope&display=swap")
  
@font-face
    font-family: American Text
    src: url('../assets/fonts/AmericanText.ttf')

@font-face
    font-family: Gotyk Poszarpany
    src: url('../assets/fonts/Gotyk Poszarpany.ttf')