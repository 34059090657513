@import 'styles/index.sass'

.Notification
  display: flex
  justify-content: center
  align-items: center
  color: #fff
  background-color: #343434
  border-radius: 40px
  margin-top: 15px
  padding: 15px 25px

.NotificationIcon
  margin-right: 10px
  width: 25px
  height: 25px