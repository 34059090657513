@import 'styles/index.sass'

.CartItem
    padding: 28px 0
    border-bottom: 1px solid #484848
    display: flex

    &:last-child
        border: none

.CartItemImageWrapper
    width: 124px
    height: 124px
    border: 1px solid #212121
    border-radius: 16px

    @media (max-width: $breakpoint-tablet-small)
        width: 112px
        height: 112px

.CartItemImage
    width: 100%
    height: 100%
    object-fit: contain
    object-position: 50% 50%

.CartItemquantity
    margin: 0 19px

.CartItemContent
    margin-left: 16px
    width: 100%

.CartItemBrand
    margin-bottom: 4px
    color: #fff
    font-family: American Text
    font-size: 22px
    line-height: 32px

    a
        color: #fff
        text-decoration: none

    @media (max-width: $breakpoint-tablet-small)
        font-size: 20px
        line-height: 29px

.CartItemSize
    margin-bottom: 5px
    color: #fff
    font-family: Manrope
    font-size: 17px
    line-height: 23px

    @media (max-width: $breakpoint-tablet-small)
        margin-bottom: 17px
        font-size: 14px
        line-height: 18px

.CartItemSizeProperty
    color: #ffffff42

.CartItemPriceWrapper
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center

.CartItemAmountButton
    width: 108px

.CartItemAmount
    margin: 0 18px
    font-weight: 700
    font-size: 16px
    line-height: 18px

.CartItemPrice
    font-weight: 500
    font-size: 20px
    line-height: 27px
    color: #fff

    @media (max-width: $breakpoint-tablet-small)
        font-size: 16px
        line-height: 18px

.CartItemNameDelete
    display: flex
    justify-content: space-between
    align-items: center

.CartItemClose
    color: #fff

.CartItemCloseIcon
    width: 14px
    height: 14px
    cursor: pointer

    @media (max-width: $breakpoint-tablet-small)
        width: 12px
        height: 12px
