@import 'styles/index.sass'

.BackgroundBlur
    width: 100vw
    min-height: 100vh
    position: fixed
    top: 0
    left: 0
    background: rgba(206, 153, 43, 0.24)
    // background: rgba(72, 41, 46, 0.5)
    // background: rgba(97, 161, 190, 0.24)
    backdrop-filter: blur(16px)
    z-index: 1

.BackgroundBlurPurg
    width: 100vw
    min-height: 100vh
    position: fixed
    top: 0
    left: 0
    background: rgba(40, 31, 31, 0.24)
    backdrop-filter: blur(16px)
    z-index: 1

.BackgroundBlurParad
    width: 100vw
    min-height: 100vh
    position: fixed
    top: 0
    left: 0
    background: rgba(206, 153, 43, 0.24)
    backdrop-filter: blur(16px)
    z-index: 1

.Background
    width: 100vw
    min-height: 100vh
    position: fixed
    top: 0
    left: 0
    filter: blur(16px)
    background-image: url('https://res.cloudinary.com/dalyoko-dan-tk/image/upload/q_80/v1662562954/celestial/background_nipskr.jpg')
    background-repeat: no-repeat
    background-size: cover
    z-index: 0

.BackgroundPurg
    width: 100vw
    min-height: 100vh
    position: fixed
    top: 0
    left: 0
    filter: blur(16px) hue-rotate(195deg) saturate(150%)
    background-image: url('https://res.cloudinary.com/dalyoko-dan-tk/image/upload/q_80/v1662562954/celestial/background_nipskr.jpg')
    background-repeat: no-repeat
    background-size: cover
    z-index: 0

.BackgroundPar
    width: 100vw
    min-height: 100vh
    position: fixed
    top: 0
    left: 0
    filter: blur(16px)
    background-image: url('https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1666851741/celestial/background_sp4sp6.jpg')
    background-repeat: no-repeat
    background-size: cover
    z-index: 0

.Wrapper
    margin-top: 54px

.Container
    width: 100%
    display: flex
    justify-content: center

.Items
    display: grid
    grid-template-columns: 1fr 1fr
    grid-gap: 40px
    z-index: 2

    @media (max-width: $breakpoint-tablet-small)
        grid-template-columns: repeat(auto-fit, 250px)

.Item
    box-sizing: border-box
    width: 346px
    height: 346px
    display: flex
    justify-content: center
    align-items: center
    position: relative
    background-image: url('~assets/images/pages/items/circle.svg')
    background-repeat: no-repeat
    background-size: contain
    background-position: 50% 50%
    cursor: pointer
    border-radius: 100%
    box-shadow: 0 0 10px 1px #fff
    animation-name: glow
    animation-duration: 3s
    animation-iteration-count: infinite
    animation-direction: alternate-reverse
    z-index: 100

    @keyframes glow
        0%
            box-shadow: 0 0 5px 0.5px #ffffff51

        100%
            box-shadow: 0 0 30px 10px #ffffff90

    @media (max-width: $breakpoint-tablet-small)
        width: 250px
        height: 250px

.ItemInSlider
    animation: none
    box-shadow: none
    
    // @media (max-width: $breakpoint-tablet-small)
    //     animation-name: glow-slider
    //     animation-duration: 3s
    //     animation-iteration-count: infinite
    //     animation-direction: alternate-reverse
    //     box-shadow: none
    
    //     @keyframes glow-slider
    //         0%
    //             box-shadow: 0 0 5px 0.5px #ffffff51
    //         100%
    //             box-shadow: 0 0 30px 5px #ffffff90

.ItemChild
    width: 25%
    height: 25%
    margin-right: 10px
    background-image: url('~assets/images/pages/items/circle.svg')
    background-repeat: no-repeat
    background-size: cover
    background-position: 50% 50%
    &:last-child
        margin-right: 0

    @media (max-width: $breakpoint-tablet-small)
        width: 70px
        height: 70px

.ItemImageWrapper
    width: 20px
    height: 20px

.ItemImageWrapper
    width: 209px
    height: 209px

.ItemImage
    width: 100%
    height: 100%
    object-fit: contain

.ItemSoon

    .ItemName
        background-clip: text
        text-fill-color: transparent

        &:nth-child(1)
            background: linear-gradient(89.71deg, rgba(255, 255, 255, 0.52) 3.13%, #FFFFFF 90.7%)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent

        &:nth-child(2)
            background: linear-gradient(97.43deg, #FFFFFF 0.37%, rgba(255, 255, 255, 0.64) 101.09%)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent

        &:nth-child(3)
            background: linear-gradient(89.71deg, #FFFFFF 3.13%, rgba(255, 255, 255, 0.52) 114.45%)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent

.ItemName
    display: block
    position: absolute
    font-family: American Text
    color: #fff
    z-index: 10
    text-transform: lowercase
    animation-name: glow-text
    animation-duration: 3s
    animation-iteration-count: infinite
    animation-direction: alternate-reverse

    @keyframes glow-text
        0%
            text-shadow: 0 0 5px #ffffff

        100%
            text-shadow: 0 0 15px #ffffff

    &:nth-child(1)
        top: 68px
        right: 30px
        transform: rotate(53deg)

        @media (max-width: $breakpoint-tablet-small)
            top: 57px
            right: 11px
            transform: rotate(58deg)
            

    &:nth-child(2)
        top: 235px
        right: 15px
        transform: rotate(120deg)

        @media (max-width: $breakpoint-tablet-small)
            top: 165px
            right: 6px
            transform: rotate(115deg)
            

    &:nth-child(3)
        top: 324px
        right: 140px
        transform: rotate(172deg)


        @media (max-width: $breakpoint-tablet-small)
            top: 232px
            right: 90px

.ItemNameWrapper
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    animation: 20s linear 0s infinite both running rotation
    transform-origin: center
    z-index: -1

    @keyframes rotation
        0%
            transform: rotate(0deg)

        100%
            transform: rotate(360deg)

.ItemSoonWrapper
    width: 304px
    height: 304px
    display: flex
    justify-content: center
    align-items: center
    background: linear-gradient(210.93deg, rgba(0, 0, 0, 0.72) 13.32%, rgba(23, 23, 23, 0.71) 91.45%)
    border-radius: 100%

    @media (max-width: $breakpoint-tablet-small)
        width: 220px
        height: 220px

.ItemSoonTitle
    font-weight: 400
    font-size: 28px
    line-height: 40px
    opacity: 0.5
    font-family: American Text
    color: #fff
    animation-name: soon
    animation-duration: 1s
    animation-iteration-count: infinite
    animation-direction: alternate-reverse

    @keyframes soon
        0%
            font-size: 28px

        100%
            font-size: 33px


