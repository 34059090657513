.Button
    display: flex
    justify-content: center
    align-items: center
    border: none
    cursor: pointer
    transition: all 0.3s ease
    
.Button_primary
    background: #fff
    color: #101010

    &:hover
        background: rgba(255, 255, 255, 0.75)
        color: #101010

.Button_primary_active
    background: rgba(255, 255, 255, 0.25)
    color: #fff

.Button_secondary
    background: #343434
    color: #fff

.Button_outlined
    background: #000
    border: 1px solid #484848
    color: #fff

    &:hover
        background: #000
        border: 1px solid #fff
        color: #fff

.Button_outlined_active
    background: #fff
    border: 1px solid #fff
    color: #101010

.Button_sm
    padding: 7px 17px
    height: 36px
    border-radius: 52px
    font-weight: 600
    font-size: 16px
    line-height: 22px

.Button_md
    height: 44px
    border-radius: 22px
    font-weight: bold
    font-size: 16px
    line-height: 20px

.Button_lg
    height: 64px
    border-radius: 40px
    font-weight: bold
    font-size: 16px
    line-height: 22px