@import 'styles/index.sass'

.MainLayoutNavbarCart
    display: flex
    justify-content: center
    position: relative
    text-decoration: none

    @media (max-width: $breakpoint-tablet-small)
        width: 18px

.MainLayoutNavbarCart_active
        color: red

        .MainLayoutNavbarCartIcon
            color: #343434

        .MainLayoutNavbarCartCount
            color: #fff

.MainLayoutNavbarCartCount
    position: absolute
    bottom: 4px
    font-family: American Text
    font-size: 15.7538px
    line-height: 134.4%
    color: #000

    @media (max-width: $breakpoint-tablet-small)
        bottom: 8px
        font-size: 11px
        line-height: 134.4%

.MainLayoutNavbarCartIcon
    color: #fff