@import 'styles/index.sass'
    
.Navbar
    padding-top: 40px
    width: 100%
    display: flex
    justify-content: center
    position: relative
    z-index: 2

    @media (max-width: $breakpoint-tablet-small)
        padding: 20px
        width: calc(100% - 20px * 2)

.Navbar_desktop
    max-width: 688px
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center

    @media (max-width: $breakpoint-tablet-small)
        display: none

.Navbar_mobile
    width: 100%
    display: none
    align-items: center
    justify-content: space-between

    @media (max-width: $breakpoint-tablet-small)
        display: flex

.NavbarLogoWrapper
    display: flex
    align-items: center

.NavbarArrow
    cursor: pointer
    animation-name: nav-arrow
    animation-duration: 1s
    animation-iteration-count: infinite
    animation-direction: alternate-reverse
    transform: rotate(180deg)

    @keyframes nav-arrow
        0%
            transform: translateX(0)

        100%
            transform: translateX(15px)

    @media (max-width: $breakpoint-tablet-small)
        margin-right: 24px
        height: 16px
        width: 29px

.NavbarLogo
    @media (max-width: $breakpoint-tablet-small)
        margin-right: 61px
        font-size: 24px
        line-height: 34px