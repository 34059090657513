@import 'styles/index.sass'

.MainWrapper
    overflow: hidden

.Wrapper
    width: 100%
    height: 100vh
    height: calc(var(--vh, 1vh) * 100)
    display: flex
    justify-content: center
    align-items: center
    background: #000
    position: absolute

.WrapWhite
    background: #ffffff

.PortalWrapper
    width: 100%
    margin: 0 auto
    height: 100vh
    height: calc(var(--vh, 1vh) * 100)
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    top: 0
    left: 0
    z-index: 0
    overflow: hidden

.VideoWrap
    height: 100vh
    height: calc(var(--vh, 1vh) * 100)
    
.PortalVideo
    height: 100%
    font-size: 2em
    z-index: 1
    position: relative
    // @media (max-width: $breakpoint-mobile-large)
    //     height: 80%
    //     width: 100%

.PortalVideoInferno
    margin-left: 2.4rem

    @media (max-width: $breakpoint-tablet-large)
        margin-left: 1rem

.PortalVideoSource
    width: 100%
    position: relative
    z-index: 1

.PortalCover
    position: absolute
    z-index: 100
    height: 100%
    aspect-ratio: 16/9
    // @media (max-width: $breakpoint-mobile-large)
    //     height: 80%

.PortalUnder
    position: absolute
    z-index: 0
    // width: 100%
    height: 100%
    aspect-ratio: 16/9
    // @media (max-width: $breakpoint-mobile-large)
    //     height: 70%

.PortalCoverImg
    width: 100%
    height: 100%

// Navigation

.Navigation
    position: absolute
    margin: auto
    display: flex
    z-index: 10000

.Title
    margin: 0 152px
    display: flex
    justify-items: center
    align-items: center
    font-weight: 400
    font-size: 44px
    line-height: 148.9%
    text-transform: uppercase
    color: #fff
    font-family: American Text
    cursor: pointer
    text-decoration: none
    z-index: 999

    @media (max-width: $breakpoint-tablet-large)
        margin: 0 77px
        font-size: 24px
        line-height: 148.9%

    @media (max-width: $breakpoint-mobile-large + 1)
        margin: 0 45px

.Arrow
    background-image: url('~assets/icons/pages/portalShow/arrow_active.png')
    background-size: contain
    background-repeat: no-repeat
    background-position: 50% 50%
    width: 64px
    height: 100px
    cursor: pointer
    position: relative
    z-index: 999
    opacity: 60%

    &:hover
        opacity: 100%

    @media (max-width: $breakpoint-tablet-large)
        width: 32px
        height: 40px

.ArrowLeft
    animation-name: arrow-left
    animation-duration: 1s
    animation-iteration-count: infinite
    animation-direction: alternate-reverse
    transform: rotate(180deg)

    @keyframes arrow-left
        0%
            transform: translateX(0) scale(-1.5)

        100%
            transform: translateX(-30px) scale(-1.5)

    @media (max-width: $breakpoint-tablet-small)
        transform: scale(1)

        &:hover
            transform: scale(-1, 1)

.ArrowRight
    animation-name: arrow-right
    animation-duration: 1s
    animation-iteration-count: infinite
    animation-direction: alternate-reverse

    @keyframes arrow-right
        0%
            transform: translateX(0) scale(1.5)

        100%
            transform: translateX(30px) scale(1.5)

    @media (max-width: $breakpoint-tablet-small)
        transform: scale(-1, 1)

.Modal
    width: 100vw
    height: 100vh
    display: none
    justify-content: center
    align-items: center
    position: absolute
    top: 0
    left: 0
    display: none
    z-index: 999

.Modal_active
    display: flex

.ModalBackground
    width: 100vw
    height: 100vh
    position: absolute
    top: 0
    left: 0
    background: rgba(0, 0, 0, 0.03)
    backdrop-filter: blur(52px)
    overflow: hidden
    z-index: 1
    filter: grayscale(100%)

@function particles($max)
    $val: random(3000)+px random(3000)+px rgba(255,255,255,0.9)
    @for $i from 1 through $max
    $val: #{$val}, random(3000)+px random(3000)+px 3px 1px rgba(255,255,255,0.9)
    @return $val

.ParticlesWrapper
    width: 100vw
    height: 100vh
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    top: 0
    left: 0
    z-index: 0
    overflow: hidden

.ParticlesContainer
    width: 520px
    height: 700px
    overflow: hidden

    @media (max-width: $breakpoint-tablet-large)
        width: 350px

.Particles
    height: 1px
    width: 1px
    animation: particles 10s linear infinite
    border-radius: 50%

    @keyframes particles
        from
            box-shadow: particles(1500)
        to
            width: 1.5px
            height: 1.5px
            box-shadow: particles(1500)

    @media (max-width: $breakpoint-tablet-large)
        @keyframes particles
            from
                box-shadow: particles(1000)
            to
                width: 1.5px
                height: 1.5px
                box-shadow: particles(1000)